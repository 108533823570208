import { useEffect, useState } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { report } from 'utils/analytics';

import Banner from 'components/index/Banner';
import Retouch from 'components/index/Retouch';

const PhotoEditor = dynamic(() => import('components/index/PhotoEditor'), {
  ssr: false,
});

const VideoEditor = dynamic(() => import('components/index/VideoEditor'), {
  ssr: false,
});

const OnlineTools = dynamic(() => import('components/index/OnlineTools'), {
  ssr: false,
});

const ALLTools = dynamic(() => import('components/index/ALLTools'), {
  ssr: false,
});

const Ratings = dynamic(() => import('components/index/Ratings'), {
  ssr: false,
});

const Blog = dynamic(() => import('components/index/Blog'), {
  ssr: false,
});

const FAQ = dynamic(() => import('components/index/FAQ'), {
  ssr: false,
});

const AppDownload = dynamic(() => import('components/index/AppDownload'), {
  ssr: false,
});

export default function Index() {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
    // 首页不需要记住之前的滚动位置
    history.scrollRestoration = 'manual';
    report('homepage_show');
  }, []);

  const pageTitle = $t({
    defaultMessage: `Free Online AI Photo Editor | Instant and Effortless Photo Editing`,
    id: 'gzP1O+',
  });

  const pageDesc = $t({
    defaultMessage:
      'Enhance videos and images with the AirBrush online AI photo editor. Enhance, retouch, and transform your images effortlessly with advanced AI tools. Perfect for quick and seamless photo improvements.',
    id: 'Z13D3c',
  });

  const pageKw = $t({
    defaultMessage: 'airbrush photos, airbrush photo editing, airbrush portrait',
    id: 'OwMEyL',
  });

  const imageAlt = $t({ defaultMessage: 'AirBrush AI Photo Editor', id: 'k4yS39' });

  const imageUrl =
    'https://blog.airbrush.com/wp-content/uploads/2024/07/AirBrush-AI-Photo-Editor.jpg';

  const faqList = [
    {
      question: $t({ defaultMessage: 'What is AirBrush?', id: 'jzPW8h' }),
      answer: $t({
        defaultMessage:
          'AirBrush is a user-friendly photo editor and video editing tool that uses advanced AI technology to enhance and transform your media. It offers features like photo enhancements, video upscaling, object removal, and background editing, delivering professional-quality results effortlessly.',
        id: 'QNdj90',
      }),
    },
    {
      question: $t({
        defaultMessage: 'Can I use AirBrush’s photo editor on mobile?',
        id: 'D5jqFc',
      }),
      answer: $t({
        defaultMessage:
          'Yes, AirBrush is accessible on mobile devices, ensuring you can edit photos anytime, anywhere. AirBrush has dedicated apps for both Android and iOS.',
        id: 't/+TEZ',
      }),
    },
    {
      question: $t({
        defaultMessage: 'Can I edit videos online with AirBrush?',
        id: 'jmz8xs',
      }),
      answer: $t({
        defaultMessage:
          'AirBrush offers an online video enhancer that lets you enhance your videos directly from your browser. You can also download AirBrush app to smooths your skin, erases dark circles, and applies makeup to your selfie videos.',
        id: 'Cli1ww',
      }),
    },
    {
      question: $t({
        defaultMessage: 'Can I remove watermarks from videos with AirBrush? ',
        id: 'gmF6cP',
      }),
      answer: $t({
        defaultMessage:
          "Yes, AirBrush's AI watermark remover allows you to effortlessly remove watermarks from your videos.",
        id: '2h19aw',
      }),
    },
    {
      question: $t({
        defaultMessage: 'How to save edited photos and videos in AirBrush? ',
        id: 'm/VZr7',
      }),
      answer: $t({
        defaultMessage:
          'After editing, click the "Save" button to download your edited photos or videos in your desired format and resolution.',
        id: 'SN+BVz',
      }),
    },
    {
      question: $t({
        defaultMessage: 'What is photo editing? ',
        id: 'Ge1lF8',
      }),
      answer: $t({
        defaultMessage:
          'Photo editing involves altering images using various tools to improve or modify their appearance, such as adjusting brightness, contrast, color, and removing imperfections.',
        id: 'dOhWQ0',
      }),
    },
    {
      question: $t({
        defaultMessage: 'How to edit the background of an image in AirBrush?',
        id: '4MgeIc',
      }),
      answer: $t({
        defaultMessage:
          'Use the "Background Remover" tool to remove the existing background and replace it with a new one from AirBrush\'s background library or upload your own.',
        id: 'PzPWtY',
      }),
    },
    {
      question: $t({
        defaultMessage: 'How to remove objects from a photo in AirBrush? ',
        id: 'mMhLXW',
      }),
      answer: $t({
        defaultMessage:
          'Use the "Object Remover" tool to select and remove unwanted objects from your photo seamlessly without leaving any traces.',
        id: 'c0rzSS',
      }),
    },
    {
      question: $t({
        defaultMessage: 'What is an AI video enhancer?',
        id: 'pCQTJ1',
      }),
      answer: $t({
        defaultMessage:
          'An AI video enhancer uses artificial intelligence to automatically improve video quality by upscaling resolution, reducing noise, and enhancing details.',
        id: 'H85CDn',
      }),
    },
    {
      question: $t({
        defaultMessage: 'How does the video enhancer for YouTube work? ',
        id: 'AHdPta',
      }),
      answer: $t({
        defaultMessage:
          "AirBrush's video enhancer optimizes video quality for YouTube by enhancing resolution, stabilizing footage, and adjusting lighting and colors for a better viewing experience.",
        id: 'AKdimv',
      }),
    },
    {
      question: $t({
        defaultMessage: 'Can I upscale videos to 4K with AirBrush? ',
        id: 'cuAhSM',
      }),
      answer: $t({
        defaultMessage:
          "Yes, AirBrush's AI video enhancer can upscale lower-resolution videos to 4K, providing sharper and more detailed visuals.",
        id: 'sZvBq3',
      }),
    },
  ];

  // @ts-ignore
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDesc} />
        <meta name="keywords" content={pageKw} />

        {/* Hreflangs */}
        <link rel="alternate" href="https://airbrush.com/" hrefLang="X-default" />
        <link rel="alternate" href="https://airbrush.com/" hrefLang="en" />
        <link rel="alternate" href="https://airbrush.com/pt" hrefLang="pt" />
        <link rel="alternate" href="https://airbrush.com/es" hrefLang="es" />
        <link rel="alternate" href="https://airbrush.com/ru" hrefLang="ru" />
        <link rel="alternate" href="https://airbrush.com/de" hrefLang="de" />
        <link rel="alternate" href="https://airbrush.com/fr" hrefLang="fr" />

        {/* Canonical标签 */}
        <link rel="canonical" href={url} />

        {/* Facebook social share info */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDesc} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={$t({ defaultMessage: 'AirBrush', id: '47CPPy' })} />
        <meta property="og:image:alt" content={imageAlt} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:secure_url" content={imageUrl} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:site" content="@AirBrushApp" />
        <meta name="twitter:description" content={pageDesc} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://blog.airbrush.com/wp-content/uploads/2024/07/AirBrush-AI-Photo-Editor.jpg"
        />

        {/* google search console */}
        <meta
          name="google-site-verification"
          content="V6DRGbgFCB7BvX7P_Ki06hta1d1xDLndH3oD6OtbgTw"
        />

        {/* trustpilot验证 */}
        <meta
          name="trustpilot-one-time-domain-verification-id"
          content="eb677ca8-be3e-4cf4-86bc-f4c51c17d92f"
        />

        {/* Structured 结构化数据 */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": "1",
                  "name": "Home",
                  "item": "${url}"
                },
                {
                  "@type": "ListItem",
                  "position": "2",
                  "name": "${pageTitle}",
                  "item": "${url}"
                }
              ]
            }
          `,
          }}
        ></script>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context": "https://schema.org/",
              "@type": "Organization",
              "url": "${url}",
              "logo": "https://blog.airbrush.com/wp-content/uploads/2024/05/AB-logo-150150.png",
              "name": "${$t({ defaultMessage: 'AirBrush', id: '47CPPy' })}",
              "image": "${imageUrl}",
              "brand": {
                "@type": "Organization"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": 4.8,
                "ratingCount": "1727952",
                "reviewCount": 1727952,
                "bestRating": 5,
                "worstRating": 1
              },
              "legalName": "${$t({
                defaultMessage: 'Pixocial Technology (Singapore) Ltd. Pte',
                id: 'MdT1ts',
              })}"
            }
          `,
          }}
        ></script>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context": "https://schema.org/",
              "@type": "WebSite",
              "name": "${pageTitle}",
              "url": "${url}",
              "sameAs": [
                "https://www.facebook.com/airbrushappofficial",
                "https://twitter.com/AirBrushApp",
                "https://www.pinterest.com/airbrushofficial/",
                "https://www.tiktok.com/@airbrushofficial",
                "https://www.instagram.com/airbrushofficial/"
              ],
              "headline": "${pageTitle}",
              "description": "${pageDesc}",
              "image": "${imageUrl}"
            }
          `,
          }}
        ></script>
      </Head>
      <div className="overflow-x-hidden">
        <Banner />
        <Retouch />
        <PhotoEditor />
        <VideoEditor />
        <OnlineTools />
        <ALLTools />
        <Ratings />
        <Blog />
        <FAQ list={faqList} />
        <AppDownload />
      </div>
    </>
  );
}
