import CfImage from 'components/common/CfImage';
import Image from 'next/image';
import PlatformDownload from '../PlatformDownload';
import styles from './styles.module.scss';

import img1 from './img/img1.png';
import img2 from './img/img2.png';
import img3 from './img/img3.png';
import img4 from './img/img4.png';
import img5 from './img/img5.png';

export default () => {
  return (
    <div className={styles.bannerWrap}>
      <div className={styles.banner}>
        <div className={styles.imageWrap}>
          <Image src={img1} alt="banner background image" priority className={styles.img1}></Image>
          <Image src={img2} alt="banner background image" priority className={styles.img2}></Image>
          <Image src={img3} alt="banner background image" priority className={styles.img3}></Image>
          <Image src={img4} alt="banner background image" priority className={styles.img4}></Image>
          <Image src={img5} alt="banner background image" priority className={styles.img5}></Image>
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <h1
              className={styles.title}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: $t(
                  {
                    defaultMessage: 'The Best Online <span>AI Photo Editor</span> for Everyone',
                    id: 'BHMZIQ',
                  },
                  {
                    span: (chunk) =>
                      `<span class="text-transparent from-ab-orange-1 to-ab-orange-2">${chunk}</span>`,
                  }
                ),
              }}
            />
            <p className={styles.desc}>
              {$t({
                defaultMessage:
                  'Welcome to AirBrush, your ultimate destination for transforming creative visions into reality with our powerful and user-friendly AI Photo Editor.',
                id: 'vEyQS/',
              })}
            </p>
          </div>
          <div className="mt-[20px] flex flex-col items-center lg:mt-[40px]">
            <p className="hidden items-center text-[#F6F6F6] opacity-50 lg:flex lg:w-[522px]">
              <span className="mr-5 inline-block h-[1px] flex-auto bg-[#F6F6F6] opacity-[0.15]"></span>
              {$t({ defaultMessage: 'Available on', id: 'kWMpjI' })}
              <span className="ml-5 inline-block h-[1px] flex-auto bg-[#F6F6F6] opacity-[0.15]"></span>
            </p>
            <div className="flex w-full flex-col justify-center px-5 lg:flex-row lg:gap-[20px] lg:px-0 lg:pt-10">
              <PlatformDownload link="https://app.adjust.com/1bwoktc4" position="homepage" />
            </div>
          </div>
        </div>
        <div className="absolute bottom-[50px] left-1/2 hidden translate-x-[-50%] lg:block">
          <CfImage
            src="https://gcs.beautyplus.com/c79e2d1b7e5e932d8a829d5271406d44.gif"
            width={20}
          />
        </div>
      </div>
    </div>
  );
};
