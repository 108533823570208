import { MOBILE_SIZE } from 'constants/common';
import { useEffect, useState } from 'react';

const useIsMobile = (initState = true) => {
  const [isMobile, setIsMobile] = useState(initState);

  useEffect(() => {
    setIsMobile(window.innerWidth < MOBILE_SIZE);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SIZE);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

export default useIsMobile;
