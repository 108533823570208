import { INDEX } from 'constants/images';

export const retouchMoveBottom = 100;

export const retouchList = [
  {
    show: INDEX.retouch['1'],
    title: 'original',
  },
  {
    show: INDEX.retouch['2'],
    title: 'makeup',
  },
  {
    show: INDEX.retouch['3'],
    title: 'remove been',
  },
  {
    show: INDEX.retouch['4'],
    title: 'face slimming',
  },
  {
    show: INDEX.retouch['5'],
    title: 'eye enlarging',
  },
  {
    show: INDEX.retouch['6'],
    title: 'mouth enlarging',
  },
];
