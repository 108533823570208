import CfImage from 'components/common/CfImage';
import { useCallback, useState } from 'react';
import useIsMobile from 'utils/useIsMobile';
import { ReactSVG } from 'react-svg';
import retouch1 from 'assets/image/retouch1.svg';
import retouch2 from 'assets/image/retouch2.svg';
import retouch3 from 'assets/image/retouch3.svg';
import retouch4 from 'assets/image/retouch4.svg';
import retouch5 from 'assets/image/retouch5.svg';
import retouch6 from 'assets/image/retouch6.svg';
import { retouchList } from '../Banner/variable';
import Button from '../Button';
import styles from './styles.module.scss';

const svgList = [retouch1, retouch2, retouch3, retouch4, retouch5, retouch6];

export default () => {
  const [retouchIndex, setRetouchIndex] = useState(0);
  const isMobile = useIsMobile();

  const retouchClick = useCallback(
    (index) => {
      let activeIndex;
      if (index === 0) {
        activeIndex = 0;
      } else if (index === retouchIndex) {
        activeIndex = index - 1;
      } else {
        activeIndex = index;
      }
      setRetouchIndex(activeIndex);
    },
    [retouchIndex]
  );

  const validCheck = useCallback(
    (index) => {
      if (index === 0) {
        if (retouchIndex > 0) {
          return false;
        }
        return true;
      }
      if (retouchIndex >= index) {
        return true;
      }
      return false;
    },
    [retouchIndex]
  );

  return (
    <div className="h-auto bg-white lg:h-[62rem]">
      <div className="flex h-[62%] flex-col items-center bg-black pt-12 text-white lg:pt-[90px]">
        <div className="flex max-w-[75rem] flex-col items-center text-center">
          <p className="mb-1.5 text-lg font-medium uppercase text-ab-orange lg:text-[22px] lg:leading-[1.1]">
            {$t({ defaultMessage: 'Retouch Selfies', id: 'p7g301' })}
          </p>
          <h2 className="mb-5 px-5 text-3xl font-bold lg:text-[44px] lg:leading-[1.2]">
            {$t({
              defaultMessage: 'Award-Winning Tools for High-Quality and Flawless Photo Retouching',
              id: 'mbzJa1',
            })}
          </h2>
          <p className="mb-8 px-5 text-base text-white opacity-70">
            {$t({
              defaultMessage:
                'AirBrush offers award-winning portrait retouching, globally recognized for its excellence. Utilizing advanced AI technology, it provides precise and exceptional enhancements, delivering professional-quality results effortlessly.',
              id: '4dkOKs',
            })}
          </p>
          <div className="mb-[30px] w-full px-[1.25rem] lg:mb-[48px] lg:w-[336px]">
            <Button link="https://app.adjust.com/1bwoktc4" className="mx-auto w-[100%]">
              {$t({ defaultMessage: 'Free Download', id: '6x1fBa' })}
            </Button>
          </div>
          {/* retouch大图 */}
          <div className="relative mb-[6.875rem] h-auto w-screen max-w-[75rem] px-7 pt-[56%] lg:mb-0">
            {retouchList.map((item, index) => (
              <div
                key={index}
                className="absolute left-0 top-0 h-full max-h-[38.125rem] w-auto rounded-[10px] transition-opacity lg:rounded-[20px]"
                style={{
                  opacity: retouchIndex >= index ? 1 : 0,
                  margin: isMobile ? '0 20px' : '0 0',
                  overflow: 'hidden',
                }}
              >
                <CfImage
                  src={item.show}
                  width={1200}
                  alt={item.title}
                  priority
                  style={{
                    width: '100%',
                    height: '100%',
                    objectPosition: 'center',
                    objectFit: 'cover',
                    overflow: 'hidden',
                    transform: isMobile ? 'scale(1.2)' : 'scale(1)',
                  }}
                />
              </div>
            ))}
            <ul
              className="absolute -bottom-16 left-2/4 flex max-w-[37.5rem] -translate-x-1/2 justify-between px-5 sm:px-0 sm:pr-0 sm:max-lg:-bottom-24 lg:bottom-28 lg:w-[40rem]"
              style={{ width: isMobile ? '100%' : 'calc(100% - 64px)' }}
            >
              {svgList.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`flex aspect-square h-auto max-w-[5.125rem] flex-1 cursor-pointer items-center justify-center rounded-2xl ${
                      index === 0 ? '' : 'ml-2 lg:ml-5'
                    }`}
                    onClick={() => retouchClick(index)}
                    style={{
                      background: validCheck(index)
                        ? 'linear-gradient(104.37deg, #FF9B52 9.97%, #FF6E37 89.57%)'
                        : 'rgba(255,255,255,0.9)',
                    }}
                  >
                    <ReactSVG
                      src={item.src}
                      className={`${styles.svgWidth} ${validCheck(index) ? styles.wrapper : ''}`}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
